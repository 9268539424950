@import "../theme";
.selectWrapper.darkTheme,
.selectWrapper {
    color: $primary1;
    color: white;    
    transition: none !important;
    :global(.ant-select-selector) {
        transition: none !important;
    }
}

.selectWrapper {
    line-height: 1 !important;
}

.smaller {
    font-size: 1rem;
    height: 30px;
    max-height: 30px;
    min-height: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.selectWrapper :global(.ant-select-selection-placeholder) {
    color: $primary5;
}

.selectWrapper :global(.ant-select-selector) {
    padding: 0 !important;
    font-size: 1.1rem !important;
    height: 1.666rem !important;
    max-height: 1.666rem !important;
    min-height: 1.666rem !important;
}

.selectWrapperItem {
    height: 1.89em;
    max-height: 1.89em;
    min-height: 1.89em;
    font-size: 1.1rem;
    padding: 0 1em;
}

.selectWrapperItem :global(.ant-select-item-option-content),
.selectWrapperItem :global(.ant-select-item-option-content) * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selectWrapperItem:global(.ant-select-item-option-active) {
    background-color: $backgroundColor !important;
    color: white;
}

.selectWrapperItem:global(.ant-select-item-option-selected) {
    background-color: $primary3 !important;
    color: white;
}

:global(.nv-table-selected) .selectWrapper :global(.ant-select-selection-item) div {
    color: white !important;
}

.selectWrapper :global(.ant-select-selection-item) div {
    line-height: 1.2em !important;
    font-weight: unset !important;
}
