            body {
                scrollbar-width: none;
            }
            
            body::-webkit-scrollbar {
                display: none;
            }
            
            .paymentRoot.darkTheme {
                background-color: #fff;
                box-shadow: 0 0 .2em 2px #444444a8;
            }
            
            .paymentRoot {
                width: 95%;
                text-align: center;
                margin: auto;
                border-radius: 5px;
                margin-top: 2em;
                overflow: hidden;
                margin-bottom: 2em;
            }
            
            .paymentVoucherHeaderdiv.darkTheme {
                background-color: #292929;
                color: #fff;
                border-bottom: #fff;
                border-width: 1px;
                border-top: 0;
                border-left: 0;
                border-right: 0;
                border-style: solid;
            }
            
            .paymentVoucherHeaderdiv.darkTheme h2 {
                color: inherit;
            }
            
            .paymentVoucherHeaderdiv h2 {
                margin: 0px;
            }
            
            .paymentDetailsHeader,
            .paymentVoucherHeader {
                width: 100%;
                text-align: left;
                padding: .2em;
                padding-left: 1em;
                display: flex;
                height: 2.5em;
            }
            
            .paymentDetailsHeader {
                height: 3.5em;
            }
            
            .paymentDetailsHeader.darkTheme,
            .paymentVoucherHeader.darkTheme {
                background-color: #292929;
                color: #fff;
                box-shadow: 0 0 .2em 2px #444444a8;
            }
            
            .paymentDetailsHeader.darkTheme h3,
            .paymentVoucherHeader.darkTheme h3 {
                color: inherit;
            }
            
            .paymentDetailsHeader h3,
            .paymentVoucherHeader h3 {
                padding: 0;
                vertical-align: middle;
                margin: auto;
                margin-left: 0;
                margin-right: 0;
            }
            
            .firstColumn {
                text-align: left;
                width: 40%;
                display: flex;
                flex-direction: column;
            }
            
            .paymentDate {
                text-align: right;
                margin: auto;
                margin-right: 0;
            }
            
            .paymentDate h3 {
                padding: 0;
                padding-bottom: .5em;
                margin: 0;
                text-align: left;
            }
            
            @media only screen and (max-width: 720px) {
                .paymentDate {
                    text-align: left;
                    margin: 0;
                    margin-top: 1em;
                }
            }
            
            @media only screen and (max-width: 500px) {
                .paymentDate {
                    text-align: left;
                    margin: 0;
                    margin-top: 2em;
                }
            }
            
            .paymentCustomer {
                width: 100%;
                margin: auto;
                margin-bottom: 0;
                margin-top: 1em;
            }
            
            .paymentSubCompany {
                width: 100%;
                text-align: right;
                margin: auto;
                margin-right: 0;
                margin-top: 1em;
            }
            
            .eamTableAmount {
                margin-right: -.5em;
            }
            
            .eamTableAmount :global(.ant-input-number) {
                width: 100%;
            }
            
            .eamTableAmount :global(.ant-input-number-input) {
                text-align: right;
            }
            
            .amountDisplay {
                text-align: right;
            }
            
            .amountDisplay :global(.ant-statistic-title) {
                font-size: 1.5rem;
                font-weight: 700;
                color: black;
            }
            
            .detailsTable {
                margin: .5em;
            }
            
            .detailsTable :global(.ant-table-row-expand-icon):hover,
            .detailsTable :global(.ant-table-row-expand-icon):focus {
                color: inherit;
            }
            .detailsTable :global(.ant-table-body){
                overflow-y: auto!important;
            }
            .detailsTable :global(td):hover,
            .detailsTable :global(td) {
                background-color: inherit!important;
            }
            
            .actionButtons {
                display: flex;
                vertical-align: middle;
                text-align: right;
                margin: auto;
                margin-right: 0;
            }
            
            .actionButtons> :global(div) {
                padding-right: 1em;
            }
            
            .detailsTable :global(.ant-table-body) {
                scrollbar-width: thin;
                -webkit-column-rule-width: thin;
                column-rule-width: thin;
                ;
                scrollbar-color: #2e2e2e #eee;
            }
            
            .detailsTable :global(.ant-table-body) {
                scrollbar-width: thin;
                column-rule-width: thin;
                scrollbar-color: #2e2e2e #eee;
            }
            
            .detailsTable :global(.ant-table-body)::-webkit-scrollbar {
                width: .5em;
            }
            
            .detailsTable :global(.ant-table-body)::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px #4443;
                box-shadow: inset 0 0 6px #4443;
                border-radius: .2em;
                background-color: #eee;
            }
            
            .detailsTable :global(.ant-table-body)::-webkit-scrollbar-thumb {
                background-color: #2e2e2e;
                border-radius: .2em;
            }
            
            .paymentVoucherNo {
                margin: 0;
                padding: 0;
                text-align: left;
            }
            
            .firstColumnSecondRow {
                margin-top: 1em;
                display: inline-flex;
                width: 100%;
            }
            
            .detailsDiv {
                padding-top: 1em;
                padding-left: 2em;
                padding-right: 2em;
                padding-bottom: 1em;
                width: 100%;
                display: flex;
            }
            
            .secondColumn {
                width: 40%;
                margin: auto;
                margin-right: 0;
            }
            
            .secondColumnSecondRow {
                margin-top: 1em;
                display: flex;
                width: fit-content;
                margin: auto;
                margin-right: 0;
            }
            
            @media only screen and (max-width: 720px) {
                .detailsDiv {
                    display: block;
                }
                .secondColumn,
                .firstColumn {
                    width: 100%;
                }
                .firstColumnSecondRow {
                    display: block;
                }
                .secondColumn {
                    margin: 0;
                    display: flex;
                    flex-direction: column-reverse;
                }
                .secondColumnSecondRow .amountDisplay {
                    margin: 0;
                    text-align: left;
                }
                .paymentCustomer {
                    margin-top: 1em;
                }
                .paymentSubCompany {
                    margin-top: 1em;
                }
                .paymentDetailsHeader h3 {
                    font-size: 2.5vh;
                }
            }
            
            @media only screen and (max-width: 500px) {
                .detailsDiv {
                    display: block;
                }
                .secondColumn,
                .firstColumn {
                    width: 100%;
                }
                .firstColumnSecondRow {
                    display: block;
                }
                .secondColumn {
                    margin: 0;
                }
                .secondColumnSecondRow .amountDisplay {
                    margin: 0;
                    text-align: left;
                }
                .paymentCustomer {
                    margin-top: 2em;
                }
                .paymentSubCompany {
                    margin-top: 2em;
                }
                .paymentDetailsHeader h3 {
                    font-size: 2.5vh;
                }
            }
            
            .right {
                padding-left: 2em;
            }
            
            .loading {
                position: fixed;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 0;
            }
            
            .loading :global(.anticon-spin) {
                font-size: 5rem;
                margin: auto;
                text-align: center;
                position: fixed;
                top: 45%;
                left: 45%;
            }
            
            .paymentRoot.darkTheme :global(.ant-input):not(:global(.ant-input-disabled), .paymentDate *) {
                border-color: #333333;
            }
            
            .paymentRoot.darkTheme :global(.ant-input):hover:not(:global(.ant-input-disabled), .paymentDate *),
            .paymentRoot.darkTheme :global(.ant-input):focus:not(:global(.ant-input-disabled), .paymentDate *) {
                -webkit-box-shadow: 0 0 .2em 2px #444444a8;
                box-shadow: 0 0 .2em 2px #444444a8;
            }
            
            .paymentCustomer.disabled.darkTheme :global(.ant-select-disabled),
            .paymentSubCompany.disabled.darkTheme :global(.ant-select-disabled) {
                color: black;
                background-color: #f5f5f5;
            }
            
            .watermark.darkTheme .h1 {
                color: #444444;
            }
            
            .watermark .h1 {
                font-size: max(13vmax, 14vmin);
                transform: rotate(-25deg);
                border-top: solid .5vw;
                border-bottom: solid .5vw;
                width: max-content;
            }
            
            .watermark .h1Wrapper {
                width: max-content;
                text-align: center;
                margin: auto;
                margin-top: 35vh;
            }
            
            .watermark {
                position: fixed;
                top: 50px;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
                background-color: rgba(0, 0, 0, .3);
            }
            
            .paymentNoWrapper {
                display: flex;
                padding-right: 1em;
            }
            
             :global(.ant-table-tbody)> :global(tr.ant-table-row):hover> :global(td) {
                color: black;
            }
            
            .loading.darkTheme :global(.anticon-spin) {
                color: #292929;
            }