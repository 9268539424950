.eamSelect {
    width: 100%;
}

.eamSelect.darkTheme:global(.ant-select-open) :global(.ant-select) {
    -webkit-box-shadow: 0 0 .2em 2px #44444488;
    box-shadow: 0 0 .2em 2px #44444488;
}

.eamSelect.darkTheme :global(.ant-select-selection-item) {
    color: #333333;
}

.eamSelect.darkTheme:global(.ant-select),
.eamSelect.darkTheme :global(.ant-select-selector),
.eamSelect.darkTheme :global(.ant-select-arrow) {
    background-color: inherit;
    border-color: #444444!important;
    color: inherit;
    font-weight: 600;
}

.title:global(.left) {
    margin: auto;
    text-align: left;
}

.title:global(.right) {
    margin: auto;
    text-align: right;
}

.title:global(.center) {
    margin: auto;
    text-align: center;
}

.title:global(.hidden) {
    display: none;
}

@media only screen and (max-width: 720px) {
    .eamCompanySelect {
        width: 20em;
    }
}

.eamSelectItem.darkTheme :global(.ant-select-item-option-selected) {
    background-color: #333333;
    border-color: #333333;
    color: #fff;
}

.eamSelect.darkTheme:global(.ant-select):active,
.eamSelect.darkTheme:global(.ant-select):hover,
.eamSelect.darkTheme:global(.ant-select-focused) :global(.ant-select-selector) {
    border-color: #444444!important;
    box-shadow: 0 0 .2em 2px #444444a8!important;
    -webkit-box-shadow: 0 0 .2em 2px #444444a8!important;
}

.eamSelectItem.darkTheme :global(.ant-select-item-option-active) {
    background-color: #444444!important;
    border-color: #444444;
    color: #fff;
}

.eamSelectItem.darkTheme.ecsiFetching :global(.ant-select-dropdown-menu-item-disabled) {
    color: rgba(0, 0, 0, 0.65);
    cursor: wait;
}

.eamSpin.darkTheme :global(.ant-spin) {
    color: rgba(0, 0, 0, 0.65)
}

.eamSpin.darkTheme {
    display: inline-flex;
}

.eamSpin.darkTheme p {
    margin: 0;
    margin-left: 1em;
}

.eamSelectItem.darkTheme :global(.ant-btn) {
    border: none;
}

.eamSelectItem.darkTheme :global(.ant-btn):hover {
    -webkit-animation-name: buttonHover;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: .3s;
    /* Safari 4.0 - 8.0 */
    animation-name: buttonHover;
    animation-duration: .3s;
    background-color: #333333;
    color: #fff;
}

@-webkit-keyframes buttonHover {
    from {
        background-color: white;
        color: rgba(0, 0, 0, 0.65);
    }
    to {
        background-color: #333333;
        color: #fff;
    }
}

@keyframes buttonHover {
    from {
        background-color: white;
        color: rgba(0, 0, 0, 0.65);
    }
    to {
        background-color: #333333;
        color: #fff;
    }
}

html {
    --antd-wave-shadow-color: #2e2e2e;
}