:global(._nt_header) th {
    border-bottom: 0.1em solid black;
}

.navTable::-webkit-scrollbar {
    width: 15px;
}

.navTable:not(:global(.virtual-grid-footer)) {
    overflow-x: hidden!important;
    scrollbar-width: auto;
    scrollbar-color: #2e2e2e #eeeeee;
}

:global(.virtual-grid-footer) {
    overflow-y: hidden!important;
    overflow-x: hidden!important;
    border-top-style: double;
}

.hidden {
    display: none;
}

.root :global(.ant-table-column-sorters) :global(.ant-table-column-title) {
    padding: 1em;
}

.navTable::-webkit-scrollbar-thumb {
    background: #2e2e2e;
}

.navTable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
}

.antBodyLoading {
    text-align: center;
    padding: 20px;
}

.hidden {
    display: none;
}

.navTable :global(.ant-table) {
    font-size: 1rem!important;
}

.navTable :global(.ant-table-thead)>:global(tr)>:global(th),
.navTable :global(.ant-table tfoot)>:global(tr)>:global(th),
.navTable :global(.ant-table tfoot)>:global(tr)>:global(td) {
    padding-top: 3px;
    padding-bottom: 3px;
}

.disableSelect {
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}

.strikeout:before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
}

.ant-table table {
    width: max-content!important;
}

.nv-table-selected.darkTheme,
.nv-table-selected.darkTheme:hover,
.nv-table-selected.darkTheme>*,
.nv-table-selected.darkTheme>*>*,
.nv-table-selected.darkTheme>*>*>* {
    background-color: #333333!important;
    color: white!important;
}

:global(.ant-table-tbody)> :global(tr):global(.ant-table-row):not(.-table-selected):hover> :global(td) {
    background-color: #fff;
    color: black!important;
}

.strikeout:before {
    border-bottom: 1px solid #111;
}

.strikeout.-table-selected.darkTheme:before {
    border-bottom: 1px solid #fff;
}

:global(.ant-dropdown-menu-item-selected) {
    background-color: #2e2e2e;
    color: #fff;
}


/* Remove colstyles*/

.root :global(.ant-table-header) colgroup>col:not(:last-child) {
    width: unset!important;
    min-width: unset!important;
}


/* .loading::after{
    content: '';
    display: block;
    position: absolute;
    left: 0px;
    top: 0;
    height: 10px;
    width: 100%;
    background: linear-gradient(to right, transparent 0%, rgb(238, 237, 237) 50%, transparent 100%);
    animation: verticalLoad 1s cubic-bezier(0.9, 0.0, 0.9, 1) infinite;
} */


/* .loading::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 30%;
    height: 25%;
    width: 25%;
    background: linear-gradient(to right, transparent 0%, rgb(238, 237, 237) 5%, transparent 100%);
    animation: load .5s infinite alternate-reverse;
}

@keyframes load {
    from {
        left: 0;
    }
    to {
        left: 75%;
    }
}


@keyframes verticalLoad {
    from {
        top: -10px;
    }
    to {
        top: 100%;
    }
} */