.authModal.noBorder :global(.ant-modal-footer){
    border:none;
}

.authModal.darkTheme :global(.ant-btn-primary){
    background-color: #2e2e2e;
    border-color: #2e2e2e;
    color: white;
}
.authModal.darkTheme :global(.ant-btn-primary):hover{
    background-color: #444;
    border-color: #444;
    color: white;
}