.title {
    top: 0;
    left: 0;
    position: sticky;
    z-index: 1;
}

.titlebar {
    display: flex;
    padding-top: .2em;
    padding-bottom: .2em;
    display: flex;
    text-align: center;
}

.titlebar> :global(h2) {
    margin: auto;
}

.titlebar>.titleLeft {
    margin: auto;
    margin-left: .5em;
}

.titlebar>.titleRight {
    margin: auto;
    margin-right: 2em;
}

.titleShortcutItem {
    padding-top: .5em;
    padding-bottom: .5em;
    padding-right: .5em;
    padding-left: .5em;
    border-style: ridge;
    width: fit-content;
}

.titleShortcutItem .titleShortcutItemKey,
.titleShortcutItem .titleShortcutItemKeyColon {
    font-size: 1.2rem;
}

.titleShortcutItem .titleShortcutItemAction {
    font-size: 1.2rem;
    padding-left: .5em;
}

.titleShortcutItem .titleShortcutItemKey {
    text-decoration: underline;
}

.titleShortcut>.titleShortcutItem:nth-child(n+2) {
    margin-left: .1em;
}

.titleShortcut {
    padding-left: .5em;
    display: flex;
}

.title> :global(h2) {
    margin: auto;
}

.title>.titleLeft {
    margin: auto;
    margin-left: .5em;
}

.title>.titleRight {
    margin: auto;
    margin-right: 2em;
}

@media only screen and (max-width: 900px) {
    .titlebar {
        display: block;
    }
    .titlebar> :global(h2),
    .titlebar>.userNameinTitle,
    .titlebar>.dateinTitle {
        margin: auto;
    }
}

.title.darkTheme {
    background-color: #292929;
}

.titleShortcutItem.darkTheme,
.titleShortcutItem.darkTheme :global(a) {
    background-color: #393939!important;
    color: white!important;
}

.titleShortcut.darkTheme {
    background-color: #393939;
    color: white;
    box-shadow: 0 0 .2em 2px #22222255;
}

.titlebar.darkTheme> :global(h2) {
    color: #fff;
}

.titlebar.darkTheme> :global(span) {
    color: #fff;
}