@mixin textfield {
    height: unset;
    font-size: 1.1rem !important;
    padding: 0;
    border: none !important;
    box-shadow: none !important;
}
@mixin textfield-select {
    background-color: #fff;
    color: black;
}

.dynamicInput {
    & :global(.ant-input-number) {
        width: 100%;
    }
    & :global(.ant-input-number) :global(.ant-input-number-input-wrap) input {
        @include textfield;
        &::selection {
            @include textfield-select;
        }
    }
    & input {
        @include textfield;
        &::selection {
            @include textfield-select;
        }
    }
}
