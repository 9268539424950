.error{
        width: max-content;
        text-align: center;
        margin: auto;
        vertical-align: middle;
        margin-top: 4em;
}
.errorImage{
    width: 35%;
    height: 35%;
    color: gray;
}
.error h1{
    color: grey ;
}