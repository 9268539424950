.eamCustomerSelect {
    width: 100%;
}

.eamCustomerSelect.darkTheme:global(.ant-select-open) :global(.ant-select) {
    -webkit-box-shadow: 0 0 .2em 2px #444444a8;
    box-shadow: 0 0 .2em 2px #444444a8;
}

.eamCustomerSelect.darkTheme :global(.ant-select-selection-item) {
    color: #333333;
}

.eamCustomerSelect.darkTheme:global(.ant-select),
.eamCustomerSelect.darkTheme :global(.ant-select-selector),
.eamCustomerSelect.darkTheme :global(.ant-select-arrow) {
    background-color: inherit;
    border-color: #444444!important;
    color: inherit;
    font-weight: 600;
}

@media only screen and (max-width: 720px) {
    .eamCompanySelect {
        width: 20em;
    }
}

.eamCustomerSelectItem.darkTheme :global(.ant-select-item-option-selected) {
    background-color: #333333;
    border-color: #333333;
    color: #fff;
}

.eamCustomerSelectItem.darkTheme :global(.ant-select-dropdown-menu-item):hover,
.eamCustomerSelectItem.darkTheme :global(.ant-select-item-option-active) {
    background-color: #444444;
    border-color: #444444;
    color: #fff;
}

.eamCustomerSelectItem.darkTheme.ecsiFetching :global(.ant-select-dropdown-menu-item-disabled) {
    color: rgba(0, 0, 0, 0.65);
    cursor: wait;
}

.eamCustomerSelect.darkTheme:global(.ant-select):active,
.eamCustomerSelect.darkTheme:global(.ant-select):hover,
.eamCustomerSelect.darkTheme:global(.ant-select-focused) :global(.ant-select-selector) {
    border-color: #444444!important;
    box-shadow: 0 0 .2em 2px #444444a8!important;
    -webkit-box-shadow: 0 0 .2em 2px #444444a8!important;
}

.eamCustomerSpin.darkTheme :global(.ant-spin) {
    color: rgba(0, 0, 0, 0.65)
}

.eamCustomerSpin.darkTheme {
    display: inline-flex;
}

.eamCustomerSpin.darkTheme p {
    margin: 0;
    margin-left: 1em;
}