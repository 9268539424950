.appLogo {
    width: 2.5rem;
    height: 2.5rem;
    background-color: transparent;
    margin: auto;
    vertical-align: middle;
}

.appHeading {
    display: none;
}

.eamCompany {
    text-align: center;
    clear: both;
    margin: auto;
    width: 60%;
}

.eamID {
    text-align: center;
    margin: auto;
    margin-left: 1em;
    margin-right: 1em;
    padding: 0;
    display: inline-flex;
    box-sizing: content-box;
    background-color: inherit;
}

.navigationBar {
    background-color: rgb(41, 41, 41, 1);
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    position: sticky;
    z-index: 1;
}

.navigationBar.darkTheme {
    box-shadow: 0 0 .5em .1em #2e2e2ea8;
}

.eamID p {
    font-size: 1.5rem;
    color: white;
    display: none;
}

@media only screen and (min-width: 960px) {
    .appHeading {
        font-size: 2rem;
        font-style: normal;
        color: white;
        display: inline-block;
        padding-right: 0px;
        margin: auto;
        margin-left: .5em;
        vertical-align: middle;
    }
    .eamID p {
        font-size: 2rem;
        color: white;
        display: inline;
    }
    .eamCompany {
        width: 50%;
    }
}

@media only screen and (max-width: 720px) {
    .eamCompany {
        width: 70%;
    }
}

@media only screen and (max-width: 350px) {
    .eamCompany {
        width: 60%;
    }
}

.homeButtondiv {
    text-align: center;
}

.homeButton {
    border: 0ch;
    border-radius: 0%;
    overflow: hidden;
    outline: none;
    cursor: pointer;
    display: inline;
    max-width: 45%;
    min-width: 7rem;
    margin: 0px 0px 0px 0%;
    background-color: transparent;
    padding: 1em;
}

.homeButton:hover {
    background-color: rgb(71, 71, 71);
}