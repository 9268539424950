.EAMIdentity {
    display: inline-flex;
    background-color: inherit;
}

.EAMIdentity p {
    color: white;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 1em;
    margin-left: 0;
    text-align: center;
    background-color: transparent;
}

.EAMIdentity.EAMIdentityLd p {
    margin-top: -0.1em;
}

.EAMIdentity.darkTheme :global(.ant-spin) {
    color: white;
}

.identityMenu.darkTheme {
    background-color: #444;
}

.identityMenuItem.darkTheme {
    color: #fff;
}

.identityMenuItem.darkTheme:hover {
    background-color: #333;
}