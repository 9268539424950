.E404 > .E404h1 {
    font-size: 20rem;
    font-weight: bolder;
    padding: 0;
    margin: 0;
    text-align: center;
    margin-top: .1em;
}
.E404  .E404h4 {
    font-size: 2rem;
    font-weight: bolder;
    color: grey;
    padding-left:1em;
    width: fit-content;
    margin:0;
}
.E404div{
    text-align: center;
    width: max-content;
    margin: auto;
    margin-top: -4em;
}