.eamCompanySelect {
    width: 100%;
}

.eamCompanySelect.darkTheme :global(.ant-select-arrow) {
    background-color: inherit;
    color: #333333;
}

.eamCompanySelect.darkTheme :global(.ant-select-selection-item) {
    color: #333333;
}

.eamCompanySelectItem.darkTheme :global(.ant-select-item-option-selected) {
    background-color: #333333;
    border-color: #333333;
    color: #fff;
}

.eamCompanySelect.darkTheme:global(.ant-select):active,
.eamCompanySelect.darkTheme:global(.ant-select):hover,
.eamCompanySelect.darkTheme:global(.ant-select-focused) :global(.ant-select-selector) {
    border-color: #444444!important;
    box-shadow: 0 0 .2em 2px #444444a8!important;
    -webkit-box-shadow: 0 0 .2em 2px #444444a8!important;
}

.eamCompanySelectItem.darkTheme :global(.ant-select-item-option-active) {
    background-color: #444444;
    border-color: #444444;
    color: #fff;
}

.eamCompanySelectItem.darkTheme.ecsiFetching :global(.ant-select-dropdown-menu-item-disabled) {
    color: rgba(0, 0, 0, 0.65);
    cursor: wait;
}

.eamCompanySpin.darkTheme :global(.ant-spin) {
    color: rgba(0, 0, 0, 0.65)
}

.eamCompanySpin.darkTheme {
    display: inline-flex;
}

.eamCompanySpin.darkTheme p {
    margin: 0;
    margin-left: 1em;
}

.eamCompanySelectItem.darkTheme :global(.ant-btn) {
    border: none;
}

.eamCompanySelectItem.darkTheme :global(.ant-btn):hover,
.eamCompanySelectItem.darkTheme :global(.ant-btn):active {
    -webkit-animation-name: buttonHover;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: .3s;
    /* Safari 4.0 - 8.0 */
    animation-name: buttonHover;
    animation-duration: .3s;
    background-color: #333333;
    color: #fff;
}

@-webkit-keyframes buttonHover {
    from {
        background-color: white;
        color: rgba(0, 0, 0, 0.65);
    }
    to {
        background-color: #333333;
        color: #fff;
    }
}

@keyframes buttonHover {
    from {
        background-color: white;
        color: rgba(0, 0, 0, 0.65);
    }
    to {
        background-color: #333333;
        color: #fff;
    }
}

html {
    --antd-wave-shadow-color: #2e2e2e;
}