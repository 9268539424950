.root {
    height: 100%;
}

:global(body) {
    overflow: hidden;
}

.spinWrapper {
    text-align: center;
    width: max-content;
    margin: auto;
    margin-top: 10%;
}

.spinWrapper :global(.ant-spin-dot-item) {
    width: 3rem!important;
    height: 3rem!important;
}

.spinWrapper :global(.ant-spin-dot) {
    font-size: 5.416rem;
}

.empty {
    margin: auto;
    text-align: center;
}

.filterBar {
    display: flex;
    width: 100%;
}

.filterLabelInner {
    padding: 1em;
    padding-left: 2em;
    width: 12em;
    margin: auto;
    text-align: center;
}

.filterLabelInner :global(.anticon) {
    margin-left: 1px;
    margin-right: 10px;
    font-size: 2rem;
}

.filterItem {
    text-align: left;
    padding: 1em;
}

.filterInner {
    display: flex;
    margin-left: .5em;
}

.filterItem:nth-child(n+2) {
    margin-left: 1em;
}

.filterItem :global(.ant-switch) {
    margin-top: .69em;
}

.displayWrapper {
    margin-top: 0em;
}

.displayWrapper::-webkit-scrollbar {
    width: 0px;
}

.displayWrapper.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    overflow-y: scroll;
}

.cardPayment {
    margin: .5em;
    height: 23em;
}

.actionsButton {
    display: flex;
}

.actionsButton>a>div,
.actionText>a {
    padding-right: 5px;
}

.addButton {
    font-size: 7rem;
    display: block;
    margin-top: 20%;
}

.addButtonWraper {
    margin: .5em;
    height: 294px;
}

.wrapper {
    margin-top: -12px;
}

.statusWrapper.hidden {
    display: none;
}

.statusWrapper {
    position: absolute;
    left: 30%;
    top: 48%;
}

.statusWrapper :global(h1) {
    @import "../../App.scss";
    @extend .bold-large;
    font-size: 2rem;
    transform: rotate(-25deg);
    border-top: solid .25vw;
    border-bottom: solid .25vw;
    width: max-content;
}


/*Color*/

.spinWrapper.darkTheme :global(.ant-spin) {
    color: #2e2e2e;
}

.selectdRowPayment {
    background-color: #444;
    color: white;
}

.spinWrapper.darkTheme :global(.ant-spin-dot-item) {
    background-color: #2e2e2e;
}

.displayWrapper.darkTheme {
    /*box-shadow: 0 0 .2em 2px #444444a8;*/
    outline: none;
}

.addButtonWraper.darkTheme :global(a) {
    color: #2e2e2e;
}

.wrapper.deleted {
    background: #00000030;
}

.cardPayment {
    -webkit-box-shadow: 9px 10px 20px -13px #444444a8;
    -moz-box-shadow: 9px 10px 20px -13px #444444a8;
    box-shadow: 9px 10px 20px -13px #444444a8;
}

.addButtonWraper {
    -webkit-box-shadow: 9px 10px 20px -13px #444444a8;
    -moz-box-shadow: 9px 10px 20px -13px #444444a8;
    box-shadow: 9px 10px 20px -13px #444444a8;
}

.filterBar.darkTheme {
    background-color: #2e2e2e;
}

.filterBar.darkTheme :global(.ant-switch-checked) {
    background-color: #ffffff50;
}

.filterLabel.darkTheme,
.filterLabel.darkTheme :global(h3) {
    background-color: #2e2e2e;
    color: white;
}

.filterInner.darkTheme {
    background-color: #2e2e2e;
    color: #fff;
}

.filterInner :global(h4) {
    color: inherit;
}

.actionText.darkTheme>a {
    color: black;
    text-decoration-line: underline;
}