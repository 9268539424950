.styledButton.darkTheme.onDark :global(.ant-btn) {
    background-color: #333333;
    color: white;
    border-color: #2e2e2e;
}

.styledButton{
    height: 100%;
}
.styledButton.darkTheme.borderLess :global(.ant-btn) {

    border: none;
}
.styledButton.darkTheme.onDark :global(.ant-btn):hover,
.styledButton.darkTheme.onDark :global(.ant-btn):focus {
    border-color: #2e2e2e;
    box-shadow: 0 0 .2em 2px #444444a8;
    -webkit-animation-name: buttonHoverDarkThemeOnDarkBg;
    -webkit-animation-duration: .2s;
    animation-name: buttonHoverDarkThemeOnDarkBg;
    animation-duration: .2s;
}

@-webkit-keyframes buttonHoverDarkThemeOnDarkBg {
    from {
        box-shadow: none;
    }
    to {
        box-shadow: 0 0 .2em 2px #444444a8;
    }
}

@keyframes buttonHoverDarkThemeOnDarkBg {
    from {
        box-shadow: none;
    }
    to {
        box-shadow: 0 0 .2em 2px #444444a8;
    }
}