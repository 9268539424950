.Pdp.darkTheme:global(.ant-input):hover,
.Pdp.darkTheme:global(.ant-input).border {
    border-color: #2e2e2e;
}

.Pdp.darkTheme.border:global(.ant-input):hover {
    -webkit-box-shadow: 0 0 .2em 2px #444444a8;
    box-shadow: 0 0 .2em 2px #444444a8;
}

.Pdp.darkTheme:global(.ant-input):focus {
    border-color: #2e2e2e;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 .2em 2px #444444a8;
    box-shadow: 0 0 .2em 2px #444444a8;
}

.Pdp.error:global(.ant-input):focus {
    border-color: #d12c2c;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 .2em 2px #994040a8;
    box-shadow: 0 0 .2em 2px #994040a8;
}

.Pdp.error:global(.ant-input):hover {
    border-color: #d12c2c;
}

.Pdp.error:global(.ant-input) {
    border-color: #d12c2c;
}