.darkTheme :global(.ant-input):not(:global(.ant-input-disabled)) {
    border-color: #333333;
}

.darkTheme :global(.ant-input):hover:not(:global(.ant-input-disabled)),
.darkTheme :global(.ant-input):focus:not(:global(.ant-input-disabled)) {
    -webkit-box-shadow: 0 0 .2em 2px #444444a8;
    box-shadow: 0 0 .2em 2px #444444a8;
}

.darkTheme :global(.ant-input)::selection {
    color: fff;
    background-color: #2e2e2e;
}