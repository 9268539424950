
.root {
    height: 100%;
    margin: 0%;
}

:global(body) {
    overflow: hidden;
}

.spinWrapper {
    text-align: center;
    width: max-content;
    margin: auto;
    margin-top: 10%;
}

.spinWrapper :global(.ant-spin-dot-item) {
    width: 24em!important;
    height: 24em!important;
}

.spinWrapper :global(.ant-spin-dot) {
    font-size: .1rem;
}

.empty {
    margin: auto;
    text-align: center;
}

.display {
    display: block;
}

.displayWrapper {
    overflow-y: scroll;
}

.displayWrapper::-webkit-scrollbar {
    width: 0px;
}

.displayWrapper.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
}

.card {
    margin: .5em;
    height: 27em;
    -webkit-box-shadow: 9px 10px 20px -13px #444444a8;
    -moz-box-shadow: 9px 10px 20px -13px #444444a8;
    box-shadow: 9px 10px 20px -13px #444444a8;
}

.filterLabelInner {
    padding: 1em;
    padding-left: 2em;
    width: 12em;
    margin: auto;
    text-align: center;
}

.filterLabelInner :global(.anticon) {
    margin-left: .083rem;
    margin-right: .83rem;
    font-size: 2rem;
}

.filterItem {
    text-align: left;
    padding: 1em;
}

.filterInner {
    display: flex;
    margin-left: .5em;
}

.filterItem:nth-child(n+2) {
    margin-left: 1em;
}

.subcompanySelectInner {
    width: 300px;
}

.filterBar {
    display: flex;
    width: 100%;
}

.filterItem :global(.ant-switch) {
    margin-top: .69em;
}

.actionsButton {
    display: flex;
}

.actionsButton>div {
    padding-right: 5px;
}

.addButton {
    font-size: 7rem;
    display: block;
    margin-top: 20%;
}

.addButtonWraper {
    margin: .5em;
    height: 294px;
    -webkit-box-shadow: 9px 10px 20px -13px #444444a8;
    -moz-box-shadow: 9px 10px 20px -13px #444444a8;
    box-shadow: 9px 10px 20px -13px #444444a8;
}

.wrapper {
    margin-top: -12px;
}

.statusWrapper.hidden {
    display: none;
}

.statusWrapper {
    position: absolute;
    left: 20%;
    top: 48%;
}

.statusWrapper :global(h1) {
    @import "../../App.scss";
    @extend .bold-large;
    font-size: 2rem;
    transform: rotate(-25deg);
    border-top: solid .25vw;
    border-bottom: solid .25vw;
    width: max-content;
}

.apCheck,
.cobCheck,
.apLoading {
    display: none;
}

.apCheck.visible,
.cobCheck.visible,
.apLoading.visible {
    display: inline;
}


/*Color*/

.spinWrapper.darkTheme :global(.ant-spin) {
    color: #2e2e2e;
}

.spinWrapper.darkTheme :global(.ant-spin-dot-item) {
    background-color: #2e2e2e;
}

.displayWrapper.darkTheme {
    /*box-shadow: 0 0 .2em 2px #444444a8;*/
    outline: none;
}

.addButtonWraper.darkTheme :global(a) {
    color: #2e2e2e;
}

.subcompanySelectInner.darkTheme {
    background-color: white;
    color: #2e2e2e;
}

.filterBar.darkTheme {
    background-color: #2e2e2e;
}

.filterBar.darkTheme :global(.ant-switch-checked) {
    background-color: #ffffff50;
}

.filterLabel.darkTheme,
.filterLabel.darkTheme :global(h3) {
    background-color: #2e2e2e;
    color: white;
}

.filterInner.darkTheme {
    background-color: #2e2e2e;
    color: #fff;
}

.filterInner :global(h4) {
    color: inherit;
}

.wrapper.closed {
    background: #00000030;
}